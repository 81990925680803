import * as React from "react";
import { TextField, Box } from "@mui/material";
import Modal from "../../modal";
import { useAdminContext } from "../../../hooks/use-admin-context";
import Button from "../../ui/button";

function SendModal({ send, callback, label }, ref) {
  const [title, setTitle] = React.useState(send.title);
  const [description, setDescription] = React.useState(send.description);
  const [path, setPath] = React.useState(send.path);
  const { closeModal } = useAdminContext();
  const classes = styles();

  const cancel = () => closeModal();

  const confirm = () => {
    callback({ title, description, path });
    closeModal();
  };

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handlePathChange = (url) => setPath(url);

  const openLinkSelector = () => {
    window.ubloLinkSelector(handlePathChange);
  };

  const actions = [
    { title: "Annuler", color: "secondary", variant: "text", onClick: cancel },
    { title: "Confirmer", color: "primary", onClick: confirm },
  ];

  return (
    <Modal title={label} actions={actions} ref={ref}>
      <TextField
        sx={classes.textfield}
        label="Titre"
        value={title}
        onChange={handleTitleChange}
        variant="standard"
      />
      <TextField
        sx={classes.textfield}
        label="Description"
        value={description}
        rows={5}
        onChange={handleDescriptionChange}
        variant="standard"
        multiline
      />
      <Box sx={classes.link}>
        <Button
          sx={classes.search}
          onClick={openLinkSelector}
          color="info"
          variant="contained"
        >
          Lien interne
        </Button>
        <div>{path || "Aucun lien sélectionné"}</div>
      </Box>
    </Modal>
  );
}

function styles() {
  return {
    textfield: {
      width: "100%",
    },
    search: {
      width: "150px",
    },
    link: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      marginTop: "20px",
      marginBottom: "20px",
      "& > div": {
        fontSize: "11px",
        fontStyle: "italic",
        wordBreak: "break-all",
      },
    },
  };
}

export default React.forwardRef(SendModal);
