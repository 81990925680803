import * as Fetcher from "./fetcher";
import * as Config from "./config";

export async function userLogin(email, password) {
  return Config.withConfiguration(async (config) => {
    const endpoint = `${config.api}/api/auth/`;
    return Fetcher.post(endpoint, { email, password });
  });
}

export async function userResetPassword(email) {
  return Config.withConfiguration(async (config) => {
    const endpoint = `${config.api}/api/auth/reset-password`;
    return Fetcher.post(endpoint, { email });
  });
}

export async function userUpdatePassword(email, password, token) {
  return Config.withConfiguration(async (config) => {
    const endpoint = `${config.api}/api/auth/update-password`;
    return Fetcher.post(endpoint, { email, password }, undefined, {
      Authorization: token,
    });
  });
}

export async function createUser(token, email, superadmin) {
  return Config.withConfiguration(async (config) => {
    const endpoint = `${config.api}/api/user/create`;
    return Fetcher.post(endpoint, { email, superadmin }, undefined, {
      Authorization: token,
    });
  });
}

export async function removeUser(token, id) {
  return Config.withConfiguration(async (config) => {
    const endpoint = `${config.api}/api/user/remove`;
    return Fetcher.post(endpoint, { id }, undefined, { Authorization: token });
  });
}

export async function getUserList(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/user/list`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function removeAppUser(token, id) {
  return Config.withConfiguration(async (config) => {
    const endpoint = `${config.api}/api/app/user/remove`;
    return Fetcher.post(endpoint, { id }, undefined, { Authorization: token });
  });
}

export async function getAppUserList(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/app/user/list`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function updateUserSuperadmin(token, email, superadmin) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/user/update-role`;
    return Fetcher.post(enpoint, { email, superadmin }, undefined, {
      Authorization: token,
    });
  });
}

export async function getFeelingTypes(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/feeling`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getFeelings(token, feelingType) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/feeling/${feelingType}`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function removeFeeling(token, feelingType, id, position) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/feeling/${feelingType}/remove`;
    return Fetcher.post(enpoint, { id, position }, undefined, {
      Authorization: token,
    });
  });
}

export async function createFeeling(
  token,
  feelingType,
  label,
  enabled,
  position
) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/feeling/${feelingType}/create`;
    return Fetcher.post(enpoint, { label, enabled, position }, undefined, {
      Authorization: token,
    });
  });
}

export async function updateFeeling(token, feelingType, feeling) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/feeling/${feelingType}/update`;
    return Fetcher.post(enpoint, feeling, undefined, { Authorization: token });
  });
}

export async function getQuotes(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/quotes`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function removeQuote(token, id) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/quotes/remove`;
    return Fetcher.post(enpoint, { id }, undefined, { Authorization: token });
  });
}

export async function createQuote(token, author, content, enabled) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/quotes/create`;
    return Fetcher.post(enpoint, { author, content, enabled }, undefined, {
      Authorization: token,
    });
  });
}

export async function updateQuote(token, id, author, content, enabled) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/quotes/update`;
    return Fetcher.post(enpoint, { id, author, content, enabled }, undefined, {
      Authorization: token,
    });
  });
}

export async function getDefinitions(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/definition`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getDefinitionKinds(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/definition/kinds`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function removeDefinition(token, id) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/definition/remove`;
    return Fetcher.post(enpoint, { id }, undefined, { Authorization: token });
  });
}

export async function createDefinition(token, word, definition, kind, enabled) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/definition/create`;
    return Fetcher.post(
      enpoint,
      { word, definition, kind, enabled },
      undefined,
      { Authorization: token }
    );
  });
}

export async function updateDefinition(
  token,
  id,
  word,
  definition,
  kind,
  enabled
) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/definition/update`;
    return Fetcher.post(
      enpoint,
      { id, word, definition, kind, enabled },
      undefined,
      { Authorization: token }
    );
  });
}

export async function getTips(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/tips`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getTip(token, id) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/tips/${id}`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function removeTip(token, id) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/tips/remove`;
    return Fetcher.post(enpoint, { id }, undefined, { Authorization: token });
  });
}

export async function createTip(token, title, content) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/tips/create`;
    return Fetcher.post(enpoint, { title, content }, undefined, {
      Authorization: token,
    });
  });
}

export async function updateTip(token, id, title, content) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/tips/update`;
    return Fetcher.post(enpoint, { id, title, content }, undefined, {
      Authorization: token,
    });
  });
}

export async function getArticles(token, type) {
  return Config.withConfiguration(async (config) => {
    const typing = type ? `/type/${type}` : "";
    const enpoint = `${config.api}/api/category/articles${typing}`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getArticle(token, id) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/articles/${id}`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function removeArticle(token, id, type) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/articles/remove`;
    return Fetcher.post(enpoint, { id, type }, undefined, {
      Authorization: token,
    });
  });
}

export async function createArticle(
  token,
  category,
  title,
  description,
  afterCancer,
  content,
  color,
  duration,
  cover,
  videoCover,
  feelings = [],
  healthConditions = [],
  carousels = [],
  labels = []
) {
  return Config.withConfiguration(async (config) => {
    const endpoint = `${config.api}/api/category/articles/create`;
    return Fetcher.post(
      endpoint,
      {
        title,
        description,
        afterCancer,
        content,
        category,
        color,
        duration,
        cover,
        videoCover,
        feelings,
        healthConditions,
        carousels,
        labels,
      },
      undefined,
      {
        Authorization: token,
      }
    );
  });
}

export async function updateArticle(
  token,
  id,
  category,
  title,
  description,
  afterCancer,
  content,
  color,
  duration,
  cover,
  videoCover,
  feelings = [],
  healthConditions = [],
  carousels = [],
  labels = []
) {
  return Config.withConfiguration(async (config) => {
    const endpoint = `${config.api}/api/category/articles/update`;
    return Fetcher.post(
      endpoint,
      {
        id,
        title,
        description,
        afterCancer,
        content,
        category,
        color,
        duration,
        cover,
        videoCover,
        feelings,
        healthConditions,
        carousels,
        labels,
      },
      undefined,
      {
        Authorization: token,
      }
    );
  });
}

export async function getCategories(token, type) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/articles/categories/${type}`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getArticleCategoryList(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/article-category/list`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getArticleCategoryKindList(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/article-category/category-kind-list`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getArticleCategoryTypeList(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/article-category/type-list`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function removeCategory(token, id) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/article-category/remove`;
    return Fetcher.post(enpoint, { id }, undefined, { Authorization: token });
  });
}

export async function createCategory(token, type, kind, title, position) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/article-category/create`;
    return Fetcher.post(enpoint, { type, kind, title, position }, undefined, {
      Authorization: token,
    });
  });
}

export async function updateCategory(token, id, type, kind, title, position) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/article-category/update`;
    return Fetcher.post(
      enpoint,
      { id, type, kind, title, position },
      undefined,
      { Authorization: token }
    );
  });
}

export async function getCarousels(token, article) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/articles/carousels/${article}`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getTags(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/tags`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getLabels(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/labels`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getDurations(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/duration`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getOrganisations(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/organisation`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getOrganisationsKinds(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/organisation/kinds`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function getOrganisation(token, id) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/organisation/${id}`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function removeOrganisation(token, id) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/organisation/remove`;
    return Fetcher.post(enpoint, { id }, undefined, { Authorization: token });
  });
}

export async function getStructure(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/structure`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function createOrganisation(
  token,
  title,
  kind,
  logo,
  description,
  address,
  email,
  phone,
  website,
  additionalDescription
) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/organisation/create`;
    return Fetcher.post(
      enpoint,
      {
        title,
        kind,
        logo,
        description,
        address,
        email,
        phone,
        website,
        additionalDescription,
      },
      undefined,
      { Authorization: token }
    );
  });
}

export async function updateOrganisation(
  token,
  id,
  title,
  kind,
  logo,
  description,
  address,
  email,
  phone,
  website,
  additionalDescription
) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/category/organisation/update`;
    return Fetcher.post(
      enpoint,
      {
        id,
        title,
        kind,
        logo,
        description,
        address,
        email,
        phone,
        website,
        additionalDescription,
      },
      undefined,
      {
        Authorization: token,
      }
    );
  });
}

export async function getNotifications(token) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/notification`;
    return Fetcher.get(enpoint, undefined, { Authorization: token });
  });
}

export async function sendNotification(token, title, description, path) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/notification/send`;
    return Fetcher.post(enpoint, { title, description, path }, undefined, {
      Authorization: token,
    });
  });
}
export async function removeNotification(token, id) {
  return Config.withConfiguration(async (config) => {
    const enpoint = `${config.api}/api/notification/remove`;
    return Fetcher.post(enpoint, { id }, undefined, { Authorization: token });
  });
}
