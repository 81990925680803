import * as React from "react";
import List from "../list";
import Card from "../card";
import RemoveModal from "./common/remove-modal";
import ListActions from "./list-actions/notifications";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../hooks/use-auth";
import { useAdminContext } from "../../hooks/use-admin-context";
import * as API from "../../services/api";

const messages = {
  deleteModal: "Supprimer une notification",
  deleteConfirm: "Etes-vous sur de vouloir supprimer cette notification ?",
  deleteSuccess: "Notification supprimée",
};

export default function Notification({ Icon }) {
  const [notifications, setNotifications] = React.useState([]);
  const [ready, setReady] = React.useState(false);

  const { token } = useAuth();
  const { openModal, triggerError, triggerSuccess, setLoader } =
    useAdminContext();

  React.useEffect(() => {
    const get = async () => {
      const result = await API.getNotifications(token);
      setNotifications(result);
      setReady(true);
    };
    get();
    return () => setNotifications(undefined);
  }, [token]);

  const remove = (id) => async () => {
    const newNotifications = await API.removeNotification(token, id);
    setNotifications(newNotifications);
    return true;
  };

  const onRemoveButtonClick = (id) =>
    openModal(<RemoveModal remove={remove(id)} messages={messages} />);

  const sendNotification = async (notification) => {
    setLoader(true);
    const { title, description, path } = notification;
    if (!title) {
      setLoader(false);
      return triggerError("Veuillez ajouter un titre.");
    }
    if (!description) {
      setLoader(false);
      return triggerError("Veuillez ajouter une description.");
    }
    const newNotifications = await API.sendNotification(
      token,
      title,
      description,
      path
    );
    setNotifications(newNotifications);
    triggerSuccess(`Nouvelle notification envoyée.`);
    setLoader(false);
  };

  const columns = [
    { field: "id", hidden: true, headerName: "id" },
    {
      field: "creationDate",
      headerName: "Date",
      width: 150,
      renderCell: renderDateCell,
    },
    { field: "title", headerName: "Titre" },
    { field: "body", headerName: "Contenu" },
  ];

  const rowActions = [{ Icon: DeleteIcon, callback: onRemoveButtonClick }];

  const actionsProps = { sendNotification };

  return (
    <Card
      Icon={Icon}
      title="Gestion des notifications"
      ListActions={ListActions}
      actionsProps={actionsProps}
    >
      <List
        width="100%"
        loading={notifications === undefined}
        columns={columns}
        rows={notifications}
        rowActions={rowActions}
        ready={ready}
      />
    </Card>
  );
}

const renderDateCell = (row) => {
  const date = new Date(row.creationDate);
  const format = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  return date.toLocaleDateString("fr-FR", format);
};
