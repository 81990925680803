import * as React from "react";
import Actions from "../../list-actions";
import SendModal from "../notifications/send-modal";
import { useAdminContext } from "../../../hooks/use-admin-context";

const initialNotification = {
  title: "",
  description: "",
  path: "",
};

export default function ListActions({ sendNotification }) {
  const { openModal } = useAdminContext();

  const onSendClick = () =>
    openModal(
      <SendModal
        send={initialNotification}
        callback={sendNotification}
        label="Envoyer une notification"
      />
    );

  return <Actions message="Envoyer une notification" action={onSendClick} />;
}
